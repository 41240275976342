import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Forward Rolls & HS Holds`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Adrian”`}</strong></p>
    <p>{`7 Rounds for time of:`}</p>
    <p>{`3-Forward Rolls`}</p>
    <p>{`5-Wall Walks`}</p>
    <p>{`7-T2B`}</p>
    <p>{`9-Box Jumps (30/24)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      